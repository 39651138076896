























































import { apiUnitAdd, apiUnitEdit } from '@/api/goods'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
  components: {
    LsDialog,
  },
})
export default class AddUnit extends Vue {
  $refs!: { lsDialog: any; form: any }
  @Prop() value: any

  @Watch('value', { deep: true })
  valueChange() {
    this.$nextTick(() => {
      this.$refs.form.clearValidate()
    })
  }

  closeDialog() {
    this.$refs.lsDialog.close()
  }

  openDialog() {
    this.$refs.lsDialog.open()
  }

  handleSave() {
    console.log(this.$refs.form)
    this.$refs.form.validate((valid: boolean, object: any) => {
      if (valid) {
        const api = this.value.id
          ? apiUnitEdit(this.value)
          : apiUnitAdd(this.value)
        api.then(() => {
          this.closeDialog()
          this.$emit('refresh')
        })
      } else {
        return false
      }
    })
  }
}
